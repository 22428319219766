
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import {
    FilterFormInterface,
    ResponseDataChart as responseChart,
    Chart,
    ChartError
} from "@/interfaces";

import * as types from "@/store/types";
import { TOKEN } from "@/services/auth/auth-service";

@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        CardProgress: () => import("@/components/layout/CardProgress.vue"),
        PlotlyChart: () => import("@/components/charts/PlotlyChart.vue"),
        FilterForm: () =>
            import("@/pages/components/forms/FilterFormStandardV2.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    }
})
export default class Analytics extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    filterFormPanelShow: boolean | null = false;
    filterForm: FilterFormInterface = {
        company: "",
        client: "",
        installation: "",
        modbus: "",
        valid: true,
        box: "",
        element: "",
        periodType: "Semanal",
        start: undefined,
        end: undefined,
        window: "1h",
        aggregation: "mean",
        comparative: false,
        days: ""
    };

    modelArimaChart: Chart = { data: [] };

    errors: ChartError[] = [];

    activated() {
        if (this.errors.length) {
            this.fetchData();
        }
    }

    /**
     * Methods
     */
    async fetchData() {
        this.setProgressBar(true);

        try {
            const startArimaDate = this.filterForm.end
                ? new Date(
                      new Date(this.filterForm.end).setDate(
                          new Date(this.filterForm.end).getDate() - 10
                      )
                  ).toISOString()
                : undefined;

            const endArimaDate = this.filterForm.end
                ? new Date(this.filterForm.end).toISOString()
                : undefined;

            const modelArimaReq = await this.$api.getModelArima<responseChart>(
                this.filterForm.box,
                this.filterForm.analyzer,
                this.filterForm.element,
                "PowerA_TOT",
                startArimaDate,
                endArimaDate,
                this.filterForm.window,
                this.filterForm.aggregation
            );

            if (modelArimaReq.ok) {
                this.modelArimaChart = modelArimaReq.data.fig;
            }
        } catch (error) {
            console.error("error", error);
            if (error instanceof Error) {
                this.errors.push({
                    chart: "arimaPrediction",
                    message: error.message
                });
                this.showError(
                    error.message
                        ? error.message
                        : "Error ploting power cosumption forecasting."
                );
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            this.fetchData();
        } else {
            this.$router.push("/signin");
        }
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    formatDates() {
        if (this.filterForm.start && this.filterForm.end)
            return (
                new Date(this.filterForm.start).toDateString() +
                " - " +
                new Date(this.filterForm.end).toDateString()
            );
        return "";
    }
}
